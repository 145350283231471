import { useTranslation } from '@pancakeswap/localization'
import {
    Flex,
    IconPhone,
    QuestionsIcon,
    SubmitSticketMenu,
    Text,
    useModal
} from '@pancakeswap/uikit'
import Link from 'next/link'
import styled from 'styled-components'
import CheckNftItem, { Item } from './CheckNftItem'
import MenuSticket from './MenuSticket'
import { linksSticket } from './config'

const SubmitSticket = () => {
    const { t } = useTranslation()


    const UserMenuItems = () => {
        return (
            <>
                <MenuSticket items={linksSticket} ml="24px" />
                <CheckNftItem />
                <Item as="button" >
                    <Flex alignItems="center" justifyContent="flex-start" width="100%" style={{ gap: '10px' }}>
                        <Link href="/questions">
                            <Flex>
                                <QuestionsIcon color="warning" width="24px" />
                                <Text ml="10px">{t("FAQs")}</Text>
                            </Flex>
                        </Link>
                    </Flex>
                </Item>
            </>
        )
    }
    return (
        <>
            <CsLinkVerichainsPC>
                <>HOTLINE: 15800005688</>
            </CsLinkVerichainsPC>
            <Flex width="fit-content" justifyContent="flex-end">
                <CsLinkVerichainsMobile
                    href="tel:15800005688" target="_blank" rel="noreferrer"
                >
                    <IconPhone />
                </CsLinkVerichainsMobile>
            </Flex>
            <SubmitSticketMenu>
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </SubmitSticketMenu>
        </>
    )
}

const CsLinkVerichainsPC = styled(Text)`
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #0436E7;
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.2px;
    display: block;
    @media screen and (max-width: 1024px){
        display: none;
        font-size: 14px;
        gap: 8px;
    }
`
const CsLinkVerichainsMobile = styled.a`
    display:flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    color: #0436E7;
    text-align: left;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.2px;
    display: none;
    &:hover{
        text-decoration: underline;
    }
    @media screen and (max-width: 1024px){
        display: flex;
        font-size: 11px;
        gap: 8px;
    }
`

export default SubmitSticket