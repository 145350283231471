import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET } from './common'

export const bscTestnetTokens = {
    wbnb: WBNB[ChainId.BSC_TESTNET],
    cake: CAKE_TESTNET,
    busd: BUSD_TESTNET,
    syrup: new Token(
        ChainId.BSC_TESTNET,
        '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
        18,
        'SYRUP',
        'SyrupBar Token',
        'https://pancakeswap.finance/',
    ),
    bake: new Token(
        ChainId.BSC_TESTNET,
        '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        18,
        'BAKE',
        'Bakeryswap Token',
        'https://www.bakeryswap.org/',
    ),
    usdt: new Token(
        ChainId.BSC_TESTNET,
        '0x3a91dc619757f89ca11dc18ec9b93699b75bca07',
        18,
        'USDT',
        'USDT',
        'https://tether.to/',
    ),
    hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
    wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
    usdc: new Token(
        ChainId.BSC_TESTNET,
        '0x2fB98DCc527c1403F92c6Bed05a50725d3005636',
        18,
        'USDC',
        'Binance-Peg USD Coin',
    ),
    runtogether: new Token(
        ChainId.BSC_TESTNET,
        '0x242a227B38f704983FF101DC7De573ED8111601e',
        18,
        'RUN ',
        'Run Together',
        'https://runtogether.net/',
    ),
    runtogetherBoxNft: new Token(
        ChainId.BSC_TESTNET,
        '0x00fbf2a59c65d495055dee6e3925ff8d7c0bdad6',
        0,
        'RTBN',
        'Run Together Box NFT',
        'https://runtogether.net/',
    ),
    pibridgeNft: new Token(
        ChainId.BSC_TESTNET,
        '0x35e128ff5C091093644BaD441FE34c8496aA3788',
        0,
        'PiBN',
        'PiBridgeMinerNft',
        'https://pibridge.org/',
    ),
    vndc: new Token(
        ChainId.BSC_TESTNET,
        '0x4534E6d66ac75d80d877E3317789303707543655',
        0,
        'VNDC',
        'ONUSPegToken',
        '',
    ),
    wrappedDetalabs1: new Token(
        ChainId.BSC_TESTNET,
        '0x7Fa98dd7DD8514B10A710fDe1e1A3b7e884D6BD0',
        0,
        'Demo1',
        'Demo1',
        '',
    ),
    wrappedDetalabs2: new Token(
        ChainId.BSC_TESTNET,
        '0x029f39F4eb4fb85E160102034dD328EE914cc5c5',
        0,
        '0609_2',
        '0609_2',
        '',
    ),
    wrappedDetalabs3: new Token(
        ChainId.BSC_TESTNET,
        '0x3237995B7675e943084dFAf37D292570597Adf38',
        0,
        '0609_3',
        '0609_3',
        '',
    ),
    wrappedDetalabs4: new Token(
        ChainId.BSC_TESTNET,
        '0xcF96973F8C049E11e5a5ACaD9C3C69e6D662B360',
        0,
        '0609_4',
        '0609_4',
        '',
    ),
    wrappedDetalabs5: new Token(
        ChainId.BSC_TESTNET,
        '0xD0533af855d6D8c7A24EbeeBE9Ec29190b1bFcC0',
        0,
        '0609_5',
        '0609_5',
        '',
    ),
    wrappedDetalabs6: new Token(
        ChainId.BSC_TESTNET,
        '0x5C96389b4fc88ee03686Bdf33152E035313710Af',
        0,
        '0609_6',
        '0609_6',
        '',
    ),
    wrappedDetalabs7: new Token(
        ChainId.BSC_TESTNET,
        '0xAE6262A840680AD92c33c0e38CE99b6b7cF660D3',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs8: new Token(
        ChainId.BSC_TESTNET,
        '0x137749b545dBd37993e7CcA04564535E4E160909',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs9: new Token(
        ChainId.BSC_TESTNET,
        '0x6dC090000C4b363B350Ea7203e034414b0983a9B',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs10: new Token(
        ChainId.BSC_TESTNET,
        '0xA08f5a50841233f4Aec9e3CDeAC1a28814ee4a03',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs11: new Token(
        ChainId.BSC_TESTNET,
        '0x5138A31515fe674Ec9092AB85201bc7172d95aa7',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs12: new Token(
        ChainId.BSC_TESTNET,
        '0xb0050c4c9AaFe1a8C51F1D853c588F6d81b12195',
        0,
        '0709_1',
        '0709_1',
        '',
    ),
    wrappedDetalabs13: new Token(
        ChainId.BSC_TESTNET,
        '0xF98Fb5e19480F4b71C9b187775913921F95852e7',
        0,
        '0809_6',
        '0809_6',
        '',
    ),
    wrappedDetalabs14: new Token(
        ChainId.BSC_TESTNET,
        '0x4cc72e2a45dA4ed196c6410406F923B85681CdB6',
        0,
        '1109_1',
        '1109_1',
        '',
    ),
    wrappedDetalabs15: new Token(
        ChainId.BSC_TESTNET,
        '0x572031D54c994a7A717c6AB9264490DA15902cEc',
        0,
        '1209_1',
        '1209_1',
        '',
    ),
    wrappedDetalabs16: new Token(
        ChainId.BSC_TESTNET,
        '0x4Fb823ff73Dece34fBA7f9ba2aDa29d7860aA833',
        0,
        '1309_1',
        '1309_1',
        '',
    ),
    wrappedDetalabs17: new Token(
        ChainId.BSC_TESTNET,
        '0xF9FE1b888d47abACf3761c4693b3872E166666df',
        0,
        '1309_2',
        '1309_2',
        '',
    ),
    wrappedDetalabs18: new Token(
        ChainId.BSC_TESTNET,
        '0x61ded8f0993dfE43498F95620C86409c32FcE836',
        0,
        '1309_3',
        '1309_3',
        '',
    ),
}
