import { useTranslation } from '@pancakeswap/localization'
import {
    Box,
    Flex,
    IconWallet,
    LogoutIcon,
    UserMenu as UIKitUserMenu,
    UserMenuItem,
    UserMenuVariant,
    useModal
} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import WalletModal, { WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'

const UserMenuMobile = () => {
    const { t } = useTranslation()
    const { address: account } = useAccount()
    const { isWrongNetwork } = useActiveChainId()
    const { logout } = useAuth()
    const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
    const [userMenuText, setUserMenuText] = useState<string>('')
    const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
    const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
    const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
    const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)

    const avatarSrc = undefined
    useEffect(() => {
        if (hasPendingTransactions) {
            setUserMenuText(t('%num% Pending', { num: pendingNumber }))
            setUserMenuVariable('pending')
        } else {
            setUserMenuText('')
            setUserMenuVariable('default')
        }
    }, [hasPendingTransactions, pendingNumber, t])

    const onClickWalletMenu = (): void => {
        if (isWrongNetwork) {
            onPresentWrongNetworkModal()
        } else {
            onPresentWalletModal()
        }
    }

    const UserMenuItems = () => {
        return (
            <>
                <div onClick={onClickWalletMenu} aria-hidden="true" className="flex gap-2 items-center py-2 px-4 hover:bg-zinc-100 w-full">
                    <IconWallet />
                    <span className="text-sm text-slate-800">
                        {t('Wallet')}
                    </span>
                </div>
                <div onClick={logout} aria-hidden="true" className="flex gap-2 items-center py-2 px-4 hover:bg-zinc-100 w-full">
                    <LogoutIcon color='primary' />
                    <span className="text-sm text-slate-800">
                        {t('Disconnect Wallet')}
                    </span>
                </div>
            </>
        )
    }

    if (account) {
        return (
            <UserMenuItems />
        )
    }

    if (isWrongNetwork) {
        return (
            <UIKitUserMenu text={t('Network')} variant="danger">
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    return (
        <CsConnectWalletButton scale="sm">
            <div aria-hidden="true" className="flex gap-2 items-center py-2 px-4 hover:bg-zinc-100 w-full">
                <IconWallet />
                <span className="text-sm text-slate-800">
                    {t('Connect Wallet')}
                </span>
            </div>
        </CsConnectWalletButton>
    )
}

export default UserMenuMobile

const CsConnectWalletButton = styled(ConnectWalletButton)`
    border: transparent;
    background-color: transparent;
    padding: 0;
    height: auto;
    width: 100%;
`
