export interface DataUserProps {
    account: string;
    type: number;
    name: string;
    nickName: string;
    image: string;
}

export const dataUser: DataUserProps[] = [
    {
        account: '0x77ecd9Ad76Eb10056d416f8a9ac55D66f29771eF',
        type: 1,
        name: 'Quỹ đầu tư khởi nghiệp sáng tạo FUNDGO',
        nickName: "FUNDGO",
        image: 'images/ccqFundgo/fungo.png',
    },
    // {
    //     account: '0x0000000000000000000000000000000000000000',
    //     type: 2,
    //     name: 'Kim Tín Phát',
    //     nickName: "Kim Tín Phát",
    //     image: 'images/ccqFundgo/ktp.png'
    // },
    // {
    //     account: '0x55F744E8715e53ED19448fdA0BEdFA6bFd4F376a',
    //     type: 2,
    //     name: 'JadeLabs Pte (Singapore)',
    //     nickName: "JadeLabs",
    //     image: 'images/ccqFundgo/jadlabs.png'
    // },
    {
        account: '0x68F9b2EFB5b328b17446beBe070dAE882853BB4B',
        type: 2,
        name: 'JadeLabs Pte (Singapore)',
        nickName: "JadeLabs",
        image: 'images/ccqFundgo/jadlabs.png'
    },
    // {
    //     account: '0xE53c4f37694987504CDD51848FE41Cbf6b982592',
    //     type: 2,
    //     name: 'Trust Card',
    //     nickName: "Trust Card",
    //     image: 'images/ccqFundgo/trust_card.png'
    // },
    // {
    //     account: '0x0000000000000000000000000000000000000000',
    //     type: 2,
    //     name: 'DTS',
    //     nickName: "DTS",
    //     image: 'images/ccqFundgo/dts.png'
    // },
]