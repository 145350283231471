
import { useTranslation } from '@pancakeswap/localization';
import { Dropdown } from 'antd';
import React from "react";
import { Colors } from "../../theme";
import { Scale } from "../Button/types";
import { Position } from "../Dropdown/types";
import { Language } from "./types";
import { IconBiChevronDown } from '../Svg';

interface Props {
    currentLang: string;
    langs: Language[];
    setLang: (lang: Language) => void;
    color: keyof Colors;
    dropdownPosition?: Position;
    buttonScale?: Scale;
    hideLanguage?: boolean;
}

const LangSelector: React.FC<React.PropsWithChildren<Props>> = ({
    currentLang,
    langs,
    setLang,
}) => {
    const { t } = useTranslation()
    return (
        <div className="mr-2 p-2 rounded-lg md:h-[46px] flex h-[41.6px]" style={{ border: "1px solid #C8C8D0" }}>
            <Dropdown
                overlayStyle={{
                    marginTop: "10px"
                }}
                placement="bottom"
                dropdownRender={() => (
                    <div className="mt-2 py-2 rounded-lg cursor-pointer bg-white shadow-lg">
                        {
                            langs.map((item) => (
                                <div
                                    aria-hidden="true"
                                    className="flex gap-2 items-center py-2 px-4 hover:bg-zinc-100"
                                    onClick={() => setLang(item)}
                                >
                                    <img className="w-[20px] h-[20px] object-contain" src={`/images/language/${item?.code.toLowerCase()}-language.png`} alt="language code" />
                                    <p className="text-sm text-slate-800">{t(item.language.toString())}</p>
                                </div>
                            ))
                        }
                    </div>
                )}
            >
                <div className="flex gap-2 gap-2 items-center cursor-pointer">
                    <img className="w-[20px] h-[20px] object-contain" src={`/images/language/${currentLang?.toLowerCase()}-language.png`} alt="language code" />
                    <p className="text-slate-800 font-semibold md:text-sm text-xs">{currentLang.toUpperCase()}</p>
                    <IconBiChevronDown />
                </div>
            </Dropdown>
        </div>
    )
};


export default React.memo(LangSelector, (prev, next) => prev.currentLang === next.currentLang);
