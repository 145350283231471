// eslint-disable-next-line lodash/import-scope

import { HelpIcon, MenuItemsType, ZaloICon } from "@pancakeswap/uikit";


export const linksSticket: MenuItemsType[] = [
    {
        label: "Zalo",
        href: "https://zalo.me/2434825341005214021",
        icon: ZaloICon,
    },
    {
        label: "Helpdesk",
        href: "https://forms.gle/eicFvRzJW3hNbFig6",
        icon: HelpIcon,
    },
];


export const ListAddress = [
    {
        title: 'a',
        address: '123',
    },
    {
        title: 'b',
        address: '456',
    },
    {
        title: 'c',
        address: '789',
    },
]