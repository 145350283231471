import { useTranslation } from '@pancakeswap/localization'
import { Flex, LinkIcon } from '@pancakeswap/uikit'
import { useModal } from 'hooks/use-modal'
import styled from 'styled-components'


const CheckNftItem = () => {
    const { t } = useTranslation()
    const { toggleModal, ModalTypeEnum } = useModal();

    return (
        <Item
            as="button"
            onClick={() => {
                toggleModal({
                    type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                    title: "",
                })
            }}
        >
            <Flex alignItems="center" justifyContent="flex-start" width="100%" style={{ gap: '10px' }}>
                <LinkIcon color="warning" width="24px" />
                {t('Fund Certificate Lookup')}
            </Flex>
        </Item>
    )
}

export default CheckNftItem

export const Item = styled.button`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${({ theme, disabled }) => theme.colors[disabled ? "textDisabled" : "text"]};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: space-between;
  outline: 0;
  padding-left: 22px;
  padding-right: 16px;
  width: 100%;
  &:is(button) {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }

  &:hover:not(:disabled) {
    border-radius:8px;
    color: ${({ theme }) => theme.colors.link};;
    > div {
      color:${({ theme }) => theme.colors.link};
    }
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`;
