
import { useTranslation } from '@pancakeswap/localization';
import { checkMortgage, formatNumberPayment } from 'config';
import moment from 'moment';
import { CQQType } from 'state/searchNFT/type';

export const Table = ({ dataList, loading, searchQuery }: { dataList: CQQType[], loading: boolean, searchQuery?: string }) => {

    if (loading && searchQuery) return (<div>Loading...</div>)
    return (
        <div className="w-full mt-4">
            <div className="lg:block hidden">
                <ItemDesktop data={dataList} />
            </div>
            <div className="lg:hidden block">
                <ItemMobile data={dataList} />
            </div>
        </div>
    )
};

const ItemMobile = ({ data }: { data: CQQType[] }) => {
    const { t } = useTranslation();
    const Item = ({ title, value }) => (
        <div className="flex justify-between">
            <p className="font-semibold text-black">{t(title)}</p>
            <p className="text-black">{value}</p>
        </div>
    );
    return (
        <div>
            {
                data.length > 0
                    ? data.map((item) => (
                        <div className="mt-2 grid gap-2" key={item.nftAddress}>
                            <Item title="Release time" value={moment(item.issueDate * 1000).format("DD/MM/YYYY")} />
                            <Item title="Fund members" value={item.fundMember} />
                            <Item title="Quantity" value={formatNumberPayment(item.totalSupply)} />
                            <Item title="Fund Certificate Type" value={t(item.isInvest ? "Fund Certificate" : "Investment rights")} />
                            <Item title="Transfer allowed" value={t(item.isTrade ? "Yes" : "No")} />
                            <Item title="Mortgage form" value={t(checkMortgage(item.mortgageStatus))} />
                        </div>
                    ))
                    : <p className="flex justify-center mt-4">{t("No data")}</p>
            }
        </div>
    )
}

const ItemDesktop = ({ data }: { data: CQQType[] }) => {
    const { t } = useTranslation()
    return (
        <div>
            <div className="grid grid-cols-6 gap-[30px] place-items-center py-4 px-2 rounded-lg" style={{ border: "1px solid #13486B" }}>
                <p className="font-semibold text-black">{t("Release time")}</p>
                <p className="font-semibold text-black">{t("Fund members")}</p>
                <p className="font-semibold text-black">{t("Quantity")}</p>
                <p className="font-semibold text-black">{t("Fund Certificate Type")}</p>
                <p className="font-semibold text-black">{t("Transfer allowed")}</p>
                <p className="font-semibold text-black">{t("Mortgage form")}</p>
            </div>
            <div className="mt-2">
                {
                    data.length > 0 ? data.map((item: CQQType) => (
                        <div className="grid grid-cols-6 gap-[30px] place-items-center py-4 px-2 rounded-lg" key={item.nftAddress}>
                            <p className='text-black'>{moment(item?.issueDate * 1000).format("DD/MM/YYYY")}</p>
                            <p className='text-black'>{item?.fundMember}</p>
                            <p className='text-black'> {formatNumberPayment(item?.totalSupply)}</p>
                            <p className='text-black'>{t(item.isInvest ? "Fund Certificate" : "Investment rights")}</p>
                            <p className='text-black'>{t(item.isTrade ? "Yes" : "No")}</p>
                            <p className='text-black'>{t(checkMortgage(item.mortgageStatus))}</p>
                        </div>
                    )) :
                        <p className="flex justify-center mt-4">{t("No data")}</p>
                }
            </div>
        </div>
    )
};