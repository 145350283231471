import { multicallAddresses } from '@pancakeswap/multicall'
import { PAYMENT, paymentTokenMarketplace97 } from 'config'

export default {
    masterChef: {
        97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
        56: '0x827d6eb00efb26a4d815706771dfde2abc84c451',
        1945: '0xfC6412C60db06DE222538d51f0585933153dB9a5',
        1975: '0xe5485c394D8d1Ce80d0D648a4c6DC9c749dda7cb',
    },
    masterChefV1: {
        97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
        56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    multiCall: multicallAddresses,
    cakeVault: {
        56: '0x45c54210128a065de780C4B0Df3d16664f7f859e',
        97: '0x683433ba14e8F26774D43D3E90DA6Dd7a22044Fe',
    },
    cakeFlexibleSideVault: {
        56: '0x615e896A8C2CA8470A2e9dc2E9552998f8658Ea0',
        97: '',
    },
    bunnySpecialXmas: {
        56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
        97: '',
    },

    AnniversaryAchievement: {
        56: '0xF839286bD9D14b358496829F3BaB3145C16ad3C1',
        97: '0x981aE96378e770DE44F89cD9175E708f9EDB70a9',
    },

    nftSale: {
        56: '0x29fE7148636b7Ae0b1E53777b28dfbaA9327af8E',
        97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    },

    // TODO: multi
    zap: {
        56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
        97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
    },
    iCake: {
        56: '0x3C458828D1622F5f4d526eb0d24Da8C4Eb8F07b1',
        97: '',
    },
    bCakeFarmBooster: {
        56: '0xE4FAa3Ef5A9708C894435B0F39c2B440936A3A52',
        97: '',
    },
    bCakeFarmBoosterProxyFactory: {
        56: '0x2C36221bF724c60E9FEE3dd44e2da8017a8EF3BA',
        97: '',
    },
    nonBscVault: {
        56: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841', // Only for pass contracts test
        1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
        5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
    },
    crossFarmingSender: {
        56: '0x327d26dE30f92600620A99043034e0A5FD9402C8', // Only for pass contracts test
        1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
        5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
    },
    crossFarmingReceiver: {
        56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
        97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
    },
    votingProposals: {
        97: "0xfca9151751cc0210db5bc310ba6530715b6e04e5",
        56: "0xac9ae9ff776b36af7746d2a759db25f04ef311ab"
    },
    poolRun: {
        97: '0x2cb575f98cf1900061df48666b46aa1341aa6db4',
        56: '0x9af4e852079af921ae63b57d66e65581b239b244',
        1945: '0x05693C7222E185311b90416b447920CEaDcD5583'
    },
    runMarketplace: {
        97: '0x65E9Daeb177E0096F4fca795683D18143E58Bafd',
        56: '0x36e75C5a3efFb2945785AeD307AA12E5dea51cF3'
    },
    runMarketplaceMultiByBox: {
        97: '0xdc9f52cc9bdd1ffcfd9292dbd9dd844047234d0f',
        56: ''
    },
    withdrawNftRun: {
        97: '0xd73f571EaC89026d9AEC4921fc1338610A1af491',
        56: '0xcE6bDB23F87804ACd99Fd6E06cF7EF91F6331bd5'
    },
    poolProposals1: {
        97: '0x2cb83a47f716b086dab25cce88f064e9305938d9',
        56: '0xbc3150a7bffdc8caee910a353ce3b64410e2b964'
    },
    poolStore1: {
        97: '0x0211eca9f5076f20243e7c396f05e87df6ba889c',
        56: '0xdf168fbfba1ac83b4a0873fc43d80e71b4e4479f'
    },
    poolProposals2: {
        97: '0x4eb52d6d798813ac3a4fb085688f79292dbbc59a',
        56: '0xf0cf8ab8320984c4ebf9c43c859153cabacb144b'
    },
    poolStore2: {
        97: '0xb8dc7826a15fa24d5d7a995e22f7ddbfada7e697',
        56: '0x3a98833b20dee5fa22dfb77d1f30ae8ef63c9d05'
    },
    mysteryBox: {
        97: '0xd6e7b57b58c9fa1bf534e8c899a19ea523b4fd85',
        56: '',
        1945: '0x134261aCCF115A2525E1E8e1981fffE0b44a690d',
        1975: '0x1AFF690C0B63cce5682B37b243373A61a60F4117',
        10001: '0x9b83bb2f49491210770daed4951c601f2f6e8fb0'
    },
    ccqMarketplace: {
        97: paymentTokenMarketplace97(PAYMENT),
        56: '',
        1945: '',
        1975: ''
    }
}
