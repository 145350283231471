import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.59844 2.40039C3.28018 2.40039 2.97495 2.52682 2.74991 2.75186C2.52487 2.97691 2.39844 3.28213 2.39844 3.60039V14.4004C2.39844 14.7187 2.52487 15.0239 2.74991 15.2489C2.97495 15.474 3.28018 15.6004 3.59844 15.6004H14.3984C14.7167 15.6004 15.0219 15.474 15.247 15.2489C15.472 15.0239 15.5984 14.7187 15.5984 14.4004V10.2004C15.5984 10.0413 15.5352 9.88865 15.4227 9.77613C15.3102 9.66361 15.1576 9.60039 14.9984 9.60039C14.8393 9.60039 14.6867 9.66361 14.5742 9.77613C14.4617 9.88865 14.3984 10.0413 14.3984 10.2004V14.4004H3.59844V3.60039H7.79844C7.95757 3.60039 8.11018 3.53718 8.2227 3.42466C8.33522 3.31213 8.39844 3.15952 8.39844 3.00039C8.39844 2.84126 8.33522 2.68865 8.2227 2.57613C8.11018 2.46361 7.95757 2.40039 7.79844 2.40039H3.59844ZM15.4232 2.57559C15.5348 2.68745 15.5978 2.8388 15.5984 2.99679V6.60039C15.5984 6.75952 15.5352 6.91213 15.4227 7.02466C15.3102 7.13718 15.1576 7.20039 14.9984 7.20039C14.8393 7.20039 14.6867 7.13718 14.5742 7.02466C14.4617 6.91213 14.3984 6.75952 14.3984 6.60039V4.44879L8.22324 10.6252C8.16745 10.681 8.10122 10.7252 8.02834 10.7554C7.95545 10.7856 7.87733 10.8011 7.79844 10.8011C7.71954 10.8011 7.64142 10.7856 7.56854 10.7554C7.49565 10.7252 7.42942 10.681 7.37364 10.6252C7.31785 10.5694 7.2736 10.5032 7.24341 10.4303C7.21322 10.3574 7.19768 10.2793 7.19768 10.2004C7.19768 10.1215 7.21322 10.0434 7.24341 9.97049C7.2736 9.8976 7.31785 9.83138 7.37364 9.77559L13.55 3.60039H11.3984C11.2393 3.60039 11.0867 3.53718 10.9742 3.42466C10.8617 3.31213 10.7984 3.15952 10.7984 3.00039C10.7984 2.84126 10.8617 2.68865 10.9742 2.57613C11.0867 2.46361 11.2393 2.40039 11.3984 2.40039H14.9984C15.0773 2.40019 15.1554 2.41556 15.2283 2.44562C15.3012 2.47569 15.3675 2.51986 15.4232 2.57559Z" fill="#13486B"/>
    </Svg>
  );
};

export default Icon;
