import { languageList, useTranslation } from '@pancakeswap/localization'
import { IconBiChevronDown, IconPhone, IconWallet, Menu as UikitMenu, HelpIcon, MenuItemsType, ZaloICon, LinkIcon, QuestionsIcon } from '@pancakeswap/uikit'
import { Dropdown } from 'antd';
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useModal } from 'hooks/use-modal';
import Link from 'next/link'
import SubmitSticket from './SubmitSticket'
import UserMenu from './UserMenu'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import UserMenuMobile from './UserMenu/index-mobile';

const Menu = (props) => {
    const { currentLanguage, setLanguage, t } = useTranslation()
    const { toggleModal, ModalTypeEnum } = useModal();
    const { pathname } = useRouter()

    const menuItems = useMenuItems()

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
    const getFooterLinks = useMemo(() => {
        return footerLinks(t)
    }, [t])

    return (
        <>
            <UikitMenu
                linkComponent={(linkProps) => {
                    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
                }}
                rightSide={
                    <>
                        <div className="md:flex hidden justify-center items-center">
                            <NetworkSwitcher />
                            <SubmitSticket />
                            <UserMenu />
                        </div>
                        <div className="md:hidden block justify-center items-center ml-2">
                            <Dropdown
                                overlayStyle={{
                                    marginTop: "10px",
                                    maxWidth: "350px"
                                }}
                                trigger={["click"]}
                                placement="bottom"
                                dropdownRender={() => (
                                    <div className="mt-2 py-2 rounded-lg cursor-pointer bg-white shadow-lg">
                                        <div style={{ borderBottom: "1px solid rgb(200, 200, 208)" }}>
                                            <UserMenuMobile />
                                        </div>
                                        <div style={{ borderBottom: "1px solid rgb(200, 200, 208)" }}>
                                            <ItemDropdown
                                                Icon={IconPhone}
                                                href="tel:15800005688"
                                                title="15800005688"
                                            />
                                            <ItemDropdown
                                                Icon={ZaloICon}
                                                href="https://zalo.me/2434825341005214021"
                                                title="Zalo"
                                            />
                                            <ItemDropdown
                                                Icon={HelpIcon}
                                                href="https://forms.gle/eicFvRzJW3hNbFig6"
                                                title="Helpdesk"
                                            />
                                        </div>
                                        <div>
                                            <ItemDropdown
                                                Icon={LinkIcon}
                                                title="Fund Certificate Lookup"
                                                onClick={() => {
                                                    toggleModal({
                                                        type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                                                        title: ""
                                                    })
                                                }}
                                            />
                                            <ItemDropdown
                                                Icon={QuestionsIcon}
                                                title="FAQs"
                                                href="/questions"
                                                inWeb
                                            />
                                        </div>
                                    </div>
                                )}
                            >
                                <div className="p-2 rounded-lg flex gap-3 items-center cursor-pointer" style={{ border: "1px solid rgb(200, 200, 208)" }}>
                                    <IconWallet />
                                    <IconBiChevronDown />
                                </div>
                            </Dropdown>
                        </div>
                    </>
                }
                links={menuItems}
                footerLinks={getFooterLinks}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                currentLang={currentLanguage.code}
                langs={languageList}
                setLang={setLanguage}
                {...props}
            />
        </>
    )
}
export default Menu;

interface ItemDropdownType {
    href?: string;
    title: string;
    Icon: any;
    onClick?: () => void;
    inWeb?: boolean;
}

const ItemDropdown = ({ href, Icon, title, onClick, inWeb = false }: ItemDropdownType) => {
    const { t } = useTranslation()
    return (
        <>
            {
                onClick
                    ? <div
                        aria-hidden="true"
                        onClick={onClick && onClick}
                        className="flex items-center gap-3 items-center py-2 px-4 hover:bg-zinc-100"
                    >
                        <Icon className="w-[20px] h-[20px]" />
                        <p className="text-slate-800">{t(title)}</p>
                    </div>
                    : <>
                        {
                            inWeb ?
                                <Link
                                    href={href}
                                >
                                    <p className="text-slate-800 flex items-center gap-3 py-2 px-4 hover:bg-zinc-100">
                                        <Icon className="w-[20px] h-[20px]" />
                                        {t(title)}
                                    </p>
                                </Link>
                                : <a
                                    href={href} target="_blank" rel="noreferrer"
                                    className="flex items-center gap-3 items-center py-2 px-4 hover:bg-zinc-100"
                                >
                                    <Icon className="w-[20px] h-[20px]" />
                                    <p className="text-slate-800"> {t(title)}</p>
                                </a>
                        }
                    </>
            }
        </>
    )
};