import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'
import { QuestionsType } from 'pages/questions'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.07)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://board.runtogether.net'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = "2500000"
export const UNIT_DATE = "days"
export const CONTENT_ERROR = "You have canceled this transaction!"
export const UNITDIFF = 'minutes'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5
export const URL_MARKETPLACE = "https://admin-portal-ccq.tss.org.vn/"
export const PAGE_SUPPORT_CHAIN = [ChainId.BSC_TESTNET]

export const PAYMENT_TOKEN = {
    VND: "VND", // VNDC
    USD: "USD", // USDT
};

export const PAYMENT = PAYMENT_TOKEN.VND;

export const PRICE_CCQ = PAYMENT === PAYMENT_TOKEN.VND ? 100000 : 10;
export const PRICE_CCQ_MINIMUM = PAYMENT === PAYMENT_TOKEN.VND ? 1000000 : 100;

export const PRICE_CCQ_STRING = PAYMENT === PAYMENT_TOKEN.VND ? "100.000" : "10";
export const PRICE_CCQ_MINIMUM_STRING = PAYMENT === PAYMENT_TOKEN.VND ? "1.000.000" : "100";

export const MINIMUM = PRICE_CCQ_MINIMUM

export const chainKey = (chainId: number) => {
    switch (chainId) {
        case ChainId.BSC:
            return "bsc_mainnet";
        case ChainId.ONUS:
            return "onus_mainnet";
        default:
            return "bsc_testnet";
    }
}

export const formatNumberPayment = (price, minimum = 0, maximum = 0) => {

    if (!price) return 0;
    if (PAYMENT === PAYMENT_TOKEN.USD) {
        return Number(new BigNumber(price).decimalPlaces(6, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: 6, maximumFractionDigits: 6 }).replace(/\.?0+$/, '')
    }
    return Number(new BigNumber(price).decimalPlaces(0, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: minimum, maximumFractionDigits: maximum })
}

export const paymentTokenMarketplace97 = (payment) => {
    switch (payment) {
        case PAYMENT_TOKEN.VND:
            return "0xa127334a8d004880f68e4C94b2Da3d43c2A366E8";
        case PAYMENT_TOKEN.USD:
            return "0x4e7B7202EDA6e0A497d5C49b8236c8a63F269cC1";
        default:
            return "";
    }
};

export const checkMortgage = (type) => {
    switch (type) {
        case 0:
            return "No mortgage";
        case 1:
            return "Stock";
        case 2:
            return "Asset";
        default: return "";
    }
};

export const ModalTypeEnum = {
    MODAL_SEARCH_CONTRACT: "MODAL_SEARCH_CONTRACT",
    NULL: "NULL",
}

export const defaultQuestions: QuestionsType[] = [
    {
        id: 1,
        title: "Who issues Fundcert Go Global Franchise Fund? Is it safe?",
        describe: "Fundcert Go Global Franchise Fund is issued by Go Global Franchise Fund and stored, verified by the Digital Asset Management Center TSS. It is safe and ensures liquidity.",
    },
    {
        id: 2,
        title: "How can I buy Fundcert Go Global Franchise Fund?",
        describe: "Please refer to this instruction link for details on how to use, buy, and sell Fundcert",
        titleLink: "https://youtu.be/sf8zNdnMKXE",
        link: "https://youtu.be/sf8zNdnMKXE"
    },
    {
        id: 3,
        title: "Does Fundcert Go Global Franchise Fund have a buyback commitment?",
        describe: "Yes, you can sell directly to the Fund members or arrange sales between users."
    },
    {
        id: 4,
        title: "What's the average time for completing a Fundcert transfer transaction?",
        describe: "Online transactions are available 24/7, with an average time of less than 3 minutes."
    },
    {
        id: 5,
        title: "Does Fundcert Go Global Franchise Fund face similar situations of non-payment like other Fundcerts when they mature?",
        describe: "On the maturity date of Fundcert Go Global Franchise Fund, you will receive both principal and interest payments to your linked wallet."
    },
    {
        id: 6,
        title: "Why do I encounter errors when placing a sell order, and how can I resolve it?",
        describe: "Please provide details of the error for system support",
    },
    {
        id: 7,
        title: "What do I need to prepare to buy Fundcert Go Global Franchise Fund?",
        describe: "A connected wallet and the amount you wish to invest."
    },
    {
        id: 8,
        title: "How does the return of Fundcert Go Global Franchise Fund compare to the market?",
        describe: "Go Global Franchise Fund's return is higher than the market, at 8% per year."
    },
    {
        id: 9,
        title: "What if I lose the wallet address? Is there any support?",
        describe: "No support in this case (since personal wallets cannot be accessed)."
    },
    {
        id: 10,
        title: "When can I receive interest payments?",
        describe: "After 3 or 6 months (equivalent to 92 or 184 days)."
    },
    {
        id: 11,
        title: "Are there any fees or taxes for customers buying Fundcert Go Global Franchise Fund?",
        describe: "When purchasing Fundcert Go Global Franchise Fund, customers need to pay the Fundcert Go Global Franchise Fund issuance fee (purchase fee) and transfer fee (if applicable)."
    },
    {
        id: 12,
        title: "What fees or taxes do customers have to pay when selling Fundcert Go Global Franchise Fund?",
        describe: "When selling Fundcert Go Global Franchise Fund, customers need to pay the Fund's repurchase fee for Fundcert Go Global Franchise Fund and transfer fee (if applicable)."
    },
    {
        id: 13,
        title: "Can customers sell a portion of Fundcert Go Global Franchise Fund?",
        describe: "Customers can sell a portion of Fundcert Go Global Franchise Fund, with a minimum of 10 units of NFT Fundcert Go Global Franchise Fund. The denomination of NFT Go Global Franchise Fund Fundcert is %money% %payment% (including 10 Fundcert Go Global Franchise Fund Certificates).",
        money: PRICE_CCQ_STRING,
        payment: PAYMENT
    },
    {
        id: 14,
        title: "What's the minimum amount for buying/selling Fundcert Go Global Franchise Fund?",
        describe: "Customers can buy/sell Fundcert Go Global Franchise Fund, with a minimum quantity of 10 units of NFT Fundcert Go Global Franchise Fund. The denomination of NFT Go Global Franchise Fund Fundcert is %money% %payment% (including 10 Fundcert Go Global Franchise Fund Certificates).",
        money: PRICE_CCQ_STRING,
        payment: PAYMENT
    },
    {
        id: 15,
        title: "How many Fundcert Go Global Franchise Fund Certificates do customers need to own to participate in investor conferences?",
        describe: "Customers only need to own Fundcert Go Global Franchise Fund Certificates on the list of participating investors on the conference day."
    },
    {
        id: 16,
        title: "What is the Fundcert Go Global Franchise Fund trading date?",
        describe: "The Fundcert Go Global Franchise Fund trading date is the day customers can buy, sell, convert Go Global Franchise Fund or transfer Fund units. The current trading days are all working days of the week."
    },
    {
        id: 17,
        title: "What's the maturity period of Fundcert Go Global Franchise Fund Certificates?",
        describe: "Fundcert Go Global Franchise Fund Certificates have a maturity period of 4 years from the issuance date."
    },
    {
        id: 18,
        title: "What's the tenure of Fundcert Go Global Franchise Fund Certificates?",
        describe: "The tenure of Fundcert Go Global Franchise Fund Certificates is 3 or 6 months (equivalent to 92 or 184 days). However, customers can sell at any time. Customers are advised that if Fundcert Go Global Franchise Fund Certificates exceed the tenure, additional profit will not be received, and Fundcert Go Global Franchise Fund Certificates have a maturity period of 4 years from the issuance date."
    },
    {
        id: 19,
        title: "How can I check my investment information after buying Fundcert Go Global Franchise Fund?",
        describe: "Investment transaction results related to your investment will be displayed directly on the e-wallet app. Customers can check transactions online 24/7."
    },
    {
        id: 20,
        title: "How long should I invest in Fundcert Go Global Franchise Fund to achieve the highest profit?",
        describe: "Fundcert Go Global Franchise Fund is a long-term investment product. In practice, history in various countries has proven that long-term investment in stocks always outperforms other investment channels. Therefore, to achieve the highest effectiveness, customers should consider Fundcert Go Global Franchise Fund as a long-term investment and accumulate for the future."
    },
    {
        id: 21,
        title: "How is the profit of Fundcert Go Global Franchise Fund calculated?",
        describe: "The profit of Fundcert Go Global Franchise Fund is calculated based on a fixed annual interest rate of up to 8%. The specific interest rate will change at each transaction time."
    },
    {
        id: 22,
        title: "Does Fundcert Go Global Franchise Fund offer a fixed interest rate commitment?",
        describe: "Fundcert Go Global Franchise Fund does not provide a fixed interest rate commitment. The interest rate may change based on market fluctuations."
    },
    {
        id: 23,
        title: "Can I transfer Fundcert Go Global Franchise Fund to others?",
        describe: "You can transfer Fundcert Go Global Franchise Fund to Fund members or arrange sales between users."
    },
    {
        id: 24,
        title: "How can I contact Go Global Franchise Fund's support department?",
        describe: "You can contact our support department via the Go Global Franchise Fund Zalo OA channel."
    },
    {
        id: 25,
        title: "Are there any investment restrictions for Fundcert Go Global Franchise Fund?",
        describe: "There are no investment restrictions for Fundcert Go Global Franchise Fund. Anyone, including individuals and organizations, can invest in Fundcert Go Global Franchise Fund."
    },
]