import React from 'react';
import { Modal } from "antd";

import { useModal } from 'hooks/use-modal';
import { ModalTypeEnum } from 'config';
import { useTranslation } from '@pancakeswap/localization';
import { ModalSearchContract } from './components';

const checkModal = (modalType: string) => {
    switch (modalType) {
        case ModalTypeEnum.MODAL_SEARCH_CONTRACT:
            return ModalSearchContract;
        default:
            return null;
    }
};

export const ContainerModal = () => {
    const { resultModal, toggleModal } = useModal();
    const { data, modal, title, type } = resultModal;
    const { t } = useTranslation()

    const WrapperModal = React.useMemo(() => checkModal(type), [type]);

    const handleCloseModal = () => {
        toggleModal({
            title: "",
            type: ModalTypeEnum.NULL
        })
    }

    if (!WrapperModal) return null;


    return (
        <Modal
            width={1400}
            open
            centered
            title={title && t(title)}
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
            footer={null}
            {...modal}
        >
            <WrapperModal {...data} />
        </Modal>
    )
};
