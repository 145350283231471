import { useTranslation } from '@pancakeswap/localization'
import {
    Button,
    Flex,
    Heading,
    IconButton,
    Input,
    InputGroup,
    ModalHeader as UIKitModalHeader,
} from '@pancakeswap/uikit'
import { LINK_VERICHAINS } from 'config/constants'
import useActiveWeb3React from "hooks/useActiveWeb3React"
import React, { useState } from 'react'
import { useSearchNFT } from 'state/searchNFT'
import { CQQType } from 'state/searchNFT/type'
import styled from 'styled-components'
import { Table } from "./table"

interface ModalSearchNFTType {
    contract?: string;
}

export const ModalSearchContract: React.FC<React.PropsWithChildren<ModalSearchNFTType>> = ({ contract }) => {

    const [searchQuery, setSearchQuery] = useState(contract)
    const [dataList, setDataList] = useState<CQQType[]>([])
    const { chainId } = useActiveWeb3React();
    const { dataCCQ, loading } = useSearchNFT(chainId)
    const { t } = useTranslation()

    React.useEffect(() => {
        if (contract) {
            const filter = dataCCQ.filter((item) => item.nftAddress.toLowerCase() === contract?.trim()?.toLowerCase());
            setDataList(filter)
        }
    }, [contract, dataCCQ]);

    const handleSearch = () => {
        const filter = dataCCQ.filter((item) => item.nftAddress.toLowerCase() === searchQuery?.trim()?.toLowerCase());
        setDataList(filter)
    }
    return (
        <div>
            <ModalHeader>
                <ModalTitle>
                    <CsHeading>{t('Fund Certificate Lookup')}</CsHeading>
                </ModalTitle>
            </ModalHeader>
            <CsModalBody width="100%" mt={["0rem", , "1rem"]}>
                <FilterControls>
                    <CustomInputGroup>
                        <Input
                            value={searchQuery}
                            placeholder={t("Enter address")}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(event.target.value)
                            }}
                        />
                    </CustomInputGroup>
                </FilterControls>
                <WrapButton>
                    <CsButtonSearch
                        onClick={handleSearch}
                        disabled={!searchQuery}
                    >{t("Search")}</CsButtonSearch>
                </WrapButton>
                <CsLinkVerichains
                    href={LINK_VERICHAINS} target="_blank" rel="noreferrer"
                >
                    Audit by Verichains
                    <img src="/images/ccqFundgo/export.png?version=1.1" alt="export" />
                </CsLinkVerichains>
                <Table dataList={dataList} loading={loading} searchQuery={searchQuery} />
            </CsModalBody>
        </div>
    )
}
const CsHeading = styled(Heading)`
  color: #13486B;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.6px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.6px;
    }
`

const ModalHeader = styled(UIKitModalHeader)`
  background: transparent !important;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    }
`


const WrapIconButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  background: black !important;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  @media screen and (max-width: 1000px) {
      height: 24px;
      width: 24px;
    }
  svg {
    fill: #fff;
    width: 25px;
    @media screen and (max-width: 1000px) {
      height: 18px;
      width: 18px;
    }
  }
`;

const ModalTitle = styled(Flex)`
  align-items: center;
  height: 40px;
  margin-top: 5px;
  flex: 1;
  justify-content: center;
  color: #23262f;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  gap: 10px;
`;

const FilterControls = styled(Flex)`
    width: 100%;
    flex-wrap: wrap;
    max-width: 480px;
    height: 58px;
    align-items: center;
    justify-content: center;
`;
const CustomInputGroup = styled(InputGroup)`
    border-radius: 12px;
    border: 1px solid transparent;
    background: transparent !important;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    > input {
        background: ${({ theme }) => theme.colors.white} !important;
        border: none;
        border-radius: 0;
        height: 48px;
        font-size: 16px;
        @media screen and (max-width: 1000px) {
            background: transparent !important;
            height: 42px;
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            height: 42px;
            background: transparent !important;
        }
        ::placeholder {
            color: ${({ theme }) => theme.colors.textDisabled};
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const WrapButton = styled(Flex)`
    width: 100%;
    justify-content: center;
    margin-top: 34px;
    max-width: 480px;
    height: 58px;
    @media screen and (max-width: 768px){
        margin-top: 17px;
    }
    @media screen and (max-width: 600px){
        margin-top: 10px;
    }
`
const CsButtonSearch = styled(Button)`
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    height: 100%;
    border-radius: 12px;
    @media screen and (max-width: 768px){
        border-radius: 12px;
        font-size: 14px;
        height: 50px;
        padding: 0 16px;
    }
    @media screen and (max-width: 600px){
        padding: 0 16px;
        font-size: 12px;
        line-height: 14px;
        height: 42px;
    }
`

const CsLinkVerichains = styled.a`
    display:flex;
    color: #13486B;
    font-size: 24px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
    @media screen and (max-width: 1024px){
        font-size: 14px;
        gap: 8px;
        margin-top: 14px;
    }
    img{
        width: 24px;
        height: 24px;
        @media screen and (max-width: 1024px){
            width: 14px;
            height: 14px;
        }
    }
`
const CsModalBody = styled(Flex)`
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y:auto;
  padding:24px!important;
  @media screen and (max-width: 768px){
    padding:0 0!important;
        }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;
