import multicall from "utils/multicall";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type";
import BigNumber from "bignumber.js";
import { CQQType } from "./type";
import { getDataEtfType } from "./getDataPhase2";

export const fetchDataCCQ = async (ccq: ccqSupportItems[], chainId): Promise<CQQType[]> => {
    try {
        const requestEtfInfo = await Promise.all(ccq.map(async (item) => {
            const [request] = await multicall(ccqWrappedAbi, [{
                address: item.ccqWrrapped.address,
                name: 'etfInfor',
                params: []
            }], chainId);
            return {
                nftAddress: item.ccqWrrapped.address,
                fundMember: item.fundMember,
                expireDate: 1,
                issueDate: new BigNumber(request.issueDate.toString()).toNumber(),
                publisher: request.publisher,
                name: request.name,
                interVestTerm: new BigNumber(request.intervestTerm.toString()).toNumber(),
                totalSupply: new BigNumber(request.totalSupply.toString()).toNumber(),
                price: new BigNumber(request.price.toString()).dividedBy(1E18).toNumber()
            }
        }));

        const getEtfType = await Promise.all(requestEtfInfo.map(async (item) => {
            const resultEtfType = await getDataEtfType(item.nftAddress, chainId)
            return {
                ...item,
                ...resultEtfType
            }
        }));
        return getEtfType;

    } catch (error) {
        return [];
    }
};

