/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "styled-components";
import { createElement, memo } from "react";
import { Flex, Text } from "@pancakeswap/uikit";
import isTouchDevice from "@pancakeswap/uikit/src/util/isTouchDevice";
import DropdownMenu from "@pancakeswap/uikit/src/components/DropdownMenu/DropdownMenu";
import { MenuItemsProps } from "@pancakeswap/uikit/src/components/MenuItems/types";

const MenuSticket: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
    items = [],
    activeItem,
    activeSubItem,
    ...props
}) => {
    return (
        <Flex flexDirection="column" {...props}>
            {items.map(({ label, items: menuItems = [], href, icon, disabled }) => {
                const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
                const isActive = activeItem === href;
                const linkProps = isTouchDevice() && menuItems && menuItems.length > 0 ? {} : { href };
                const Icon = icon;
                return (
                    <DropdownMenu
                        key={`${label}#${href}`}
                        items={menuItems}
                        py={1}
                        activeItem={activeSubItem}
                        isDisabled={disabled}
                    >
                        <CsFlex alignItems="center" width="100%" justifyContent="flex-start">
                            {(icon && createElement(Icon as any, { color: isActive ? "secondary" : "textSubtle" }))}
                            {
                                href ? <a href={href} target="_blank" rel="noreferrer"> <Text>{label}</Text></a> : <Text>{label}</Text>
                            }
                        </CsFlex>
                    </DropdownMenu>
                );
            })}
        </Flex>
    );
};

export default memo(MenuSticket);


export const CsFlex = styled(Flex)`
  align-items: center;
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 48px;
  justify-content: flex-start;
  outline: 0;
  gap: 10px;
  width: 100%;
  &:hover:not(:disabled) {
    border-radius:8px;
    color: ${({ theme }) => theme.colors.link};;
    > div {
      color:${({ theme }) => theme.colors.link};
    }
  }

  &:active:not(:disabled) {
    opacity: 0.85;
    transform: translateY(1px);
  }
`;
