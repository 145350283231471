import { createAction } from '@reduxjs/toolkit'
import { ListItems, TotalSellProps, ListCartItems, DataUser } from "./type"

export const valueSearch = createAction<{ fillPrice: string }>('marketplace/valueSearch')
export const fetchTotalSellItem = createAction<TotalSellProps>('marketplace/fetchTotalSellItem')
export const fetchDataOnMarketplace = createAction<ListItems>('marketplace/fetchDataOnMarketplace')
export const fetchStatusLoading = createAction<{ isLoading: boolean }>('marketplace/fetchStatusLoading')
export const fetchCartItems = createAction<ListCartItems>('marketplace/fetchCartItems')
export const fetchDataUserStatus = createAction<{ isDataFetching: boolean }>('marketplace/fetchDataUserStatus')

export const fetchDataUserAction = createAction<DataUser>('marketplace/fetchDataUserAction')


// for sort
export const selectDenominations = createAction<{ denominations: number }>('marketplace/selectDenominations')
export const selectPaymentPeriod = createAction<{ paymentPeriod: number }>('marketplace/selectPaymentPeriod')
export const selectNextInterestPaymentDate = createAction<{ nextInterestPaymentDate: number }>('marketplace/selectNextInterestPaymentDate')
export const selectCurrentYield = createAction<{ currentYield: number }>('marketplace/selectCurrentYield')
export const actionSearchFundMember = createAction<string>('marketplace/actionSearchFundMember')
export const actionIsShowCCQ = createAction<boolean>('marketplace/actionIsShowCCQ');
export const actionIsOpenCartCCQ = createAction<boolean>('marketplace/actionIsOpenCartCCQ');

