import { ccqSupport } from "config/constants/ccqSupport/constants"
import React, { useEffect } from "react"
import { fetchDataCCQ } from "./fetchData"

export const useSearchNFT = (chainId: number) => {
    const ccq = ccqSupport(chainId);
    const [dataCCQ, setDataCCQ] = React.useState([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    useEffect(() => {
        (async () => {
            if (ccq?.length) {
                setLoading(true);
                const result = await fetchDataCCQ(ccq, chainId);
                setDataCCQ(result);
                setLoading(false);
            }
        })()
    }, [ccq, chainId]);

    return {
        dataCCQ,
        loading
    }
}

