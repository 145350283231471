import React from 'react';

import { storeModal, useAppDispatch, useAppSelector } from 'state';
import { openModal } from 'state/modal/modal-slice';
import { ModalType } from 'type';
import { ModalTypeEnum } from 'config';

export const useModal = () => {
    const resultModal = useAppSelector(storeModal);
    const dispatch = useAppDispatch();
    const toggleModal = React.useCallback((data: ModalType) => dispatch(openModal(data)), [dispatch]);
    return {
        resultModal,
        toggleModal,
        ModalTypeEnum
    }
}
